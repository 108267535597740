
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import React from 'react';

import {BrowserRouter, Routes, Route} from 'react-router-dom';
import HomePage from './components/home/Home';
import AboutPage from './components/about/About';
import ContactPage from './components/contact/Contact';
import Menu from './components/menu/Menu'
import Hours from './components/hours/Hours'
// import NotFoundPage from './components/NotFoundPage';


function App() {
  return (
    <div >
    <BrowserRouter>
    <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/about" element={<AboutPage/>} />
          <Route path="/contact" element={<ContactPage/>} />
          <Route path="/menu" element={<Menu/>} />
          <Route path="/hours" element={<Hours/>} />

          {/* <Route component={NotFoundPage} /> */}
          </Routes>
          </BrowserRouter>

          <Footer />


</div>
  );
}

export default App;
