import React from 'react'

export default function Habout() {
  return (
    <div>
         {/* About Start */}
<div className="container-xxl py-5">
  <div className="container">
    <div className="row g-5 align-items-center">
      <div className="col-lg-6">
        <div className="row g-3">
          <div className="col-6 text-start">
            <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.1s" src="img/about-1.jpg" />
          </div>
          <div className="col-6 text-start">
            <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.3s" src="img/about-2.jpg" style={{marginTop: '25%'}} />
          </div>
          <div className="col-6 text-end">
            <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.5s" src="img/about-3.jpg" />
          </div>
          <div className="col-6 text-end">
            <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.7s" src="img/about-4.jpg" />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <h5 className="section-title ff-secondary text-start text-primary fw-normal">About Us</h5>
        <h1 className="mb-4">Welcome to <i className="fa fa-utensils text-primary me-2" />Harrods Grill</h1>
        <p className="mb-4">Tewhere flavor meets convenience! Nestled in the heart of Stanford Le Hope, Essex, our fast
         food restaurant is dedicated to serving up mouthwatering dishes that tantalize your taste buds and leave you craving for more.</p>
        <p className="mb-4">Established in June 2017 by Mr. Adrian Venoin, Harrods Grill has quickly become a 
        beloved destination for food enthusiasts seeking delicious meals in a casual dining atmosphere. 
        Our commitment to quality ingredients, expert preparation, and friendly service sets us apart, making every visit to Harrods Grill a memorable experience.</p>
        <div className="row g-4 mb-4">
          <div className="col-sm-6">
            <div className="d-flex align-items-center border-start border-5 border-primary px-3">
              <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">7</h1>
              <div className="ps-4">
                <p className="mb-0">Years of</p>
                <h6 className="text-uppercase mb-0">Experience</h6>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="d-flex align-items-center border-start border-5 border-primary px-3">
              <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">15</h1>
              <div className="ps-4">
                <p className="mb-0">Popular</p>
                <h6 className="text-uppercase mb-0">Master Chefs</h6>
              </div>
            </div>
          </div>
        </div>
        {/* <a className="btn btn-primary py-3 px-5 mt-2" href>Read More</a> */}
      </div>
    </div>
  </div>
</div>
{/* About End */}
    </div>
  )
}
