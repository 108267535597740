import React from 'react'

export default function Footer() {
  return (
    <div>
       {/* Footer Start */}
<div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
  <div className="container py-5">
    <div className="row g-5">
      <div className="col-lg-3 col-md-6">
        <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">Company</h4>
        <a className="btn btn-link" href>About Us</a>
        <a className="btn btn-link" href>Contact Us</a>
        <a className="btn btn-link" href>Reservation</a>
        <a className="btn btn-link" href>Privacy Policy</a>
        <a className="btn btn-link" href>Terms &amp; Condition</a>
      </div>
      <div className="col-lg-3 col-md-6">
        <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">Contact</h4>
        <p className="mb-2"><i className="fa fa-map-marker-alt me-3" /> High Road, Fobbing, Stanford Le Hope, ESS, SS17 9NR</p>
        <p className="mb-2"><i className="fa fa-phone-alt me-3" />0141 611 1936</p>
        <p className="mb-2"><i className="fa fa-envelope me-3" />clearbusinessbyadrian@gmail.com</p>
        <div className="d-flex pt-2">
          <a className="btn btn-outline-light btn-social" href><i className="fab fa-twitter" /></a>
          <a className="btn btn-outline-light btn-social" href><i className="fab fa-facebook-f" /></a>
          <a className="btn btn-outline-light btn-social" href><i className="fab fa-youtube" /></a>
          <a className="btn btn-outline-light btn-social" href><i className="fab fa-linkedin-in" /></a>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">Opening</h4>
        <h5 className="text-light fw-normal">Monday - Sunday</h5>
        <p>01:00PM - 11:00PM</p>
        {/* <h5 className="text-light fw-normal">Sunday</h5>
        <p>10AM - 08PM</p> */}
      </div>
      <div className="col-lg-3 col-md-6">
        {/* <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">Newsletter</h4> */}
        {/* <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p> */}
         <div className="position-relative mx-auto" style={{maxWidth: 400}}>
          {/* <input className="form-control border-primary w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" /> */}
          {/* <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button> */}
        </div> 
        
      </div>
    </div>
  </div>

</div>
{/* Footer End */}


    </div>
  )
}
