import React from 'react'

export default function Service() {
  return (
    <div>
     {/* Service Start */}
<div className="container-xxl py-5">
  <div className="container">
    <div className="row g-4">
      <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
        <div className="service-item rounded pt-3">
          <div className="p-4">
            <i className="fa fa-3x fa-user-tie text-primary mb-4" />
            <h5>Master Chefs</h5>
            <p>Culinary artisans crafting unforgettable flavors.</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
        <div className="service-item rounded pt-3">
          <div className="p-4">
            <i className="fa fa-3x fa-utensils text-primary mb-4" />
            <h5>Quality Food</h5>
            <p> Freshness meets flavor in every delectable dish.</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
        <div className="service-item rounded pt-3">
          <div className="p-4">
            <i className="fa fa-3x fa-cart-plus text-primary mb-4" />
            <h5>Online Order</h5>
            <p> Conveniently satisfy cravings with easy clicks.</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
        <div className="service-item rounded pt-3">
          <div className="p-4">
            <i className="fa fa-3x fa-headset text-primary mb-4" />
            <h5>24/7 Service</h5>
            <p>Round-the-clock satisfaction, always at your service.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* Service End */}

    </div>
  )
}
