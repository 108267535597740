import React from 'react'

export default function Hours() {
  return (
    <div>
    {/* Navbar & Hero Start */}
    <div class="container-xxl position-relative p-0">
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark px-4 px-lg-5 py-3 py-lg-0">
<a href className="navbar-brand p-0">
 <h1 className="text-primary m-0"><i className="fa fa-utensils me-3" />Harrods  Grill</h1>
 {/* <img src="img/logo.png" alt="Logo"> */}
</a>
<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
 <span className="fa fa-bars" />
</button>
<div className="collapse navbar-collapse" id="navbarCollapse">
 <div className="navbar-nav ms-auto py-0 pe-4">
 <a href="/" className="nav-item nav-link">Home</a>
   <a href="about" className="nav-item nav-link">About</a>
   <a href="menu" className="nav-item nav-link">Menu</a>
   <a href="hours" className="nav-item nav-link active">Opening Hours</a>

   {/* <div className="nav-item dropdown">
     <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
     <div className="dropdown-menu m-0">
       <a href="booking.html" className="dropdown-item">Booking</a>
       <a href="team.html" className="dropdown-item">Our Team</a>
       <a href="testimonial.html" className="dropdown-item">Testimonial</a>
     </div>
   </div> */}
   <a href="contact" className="nav-item nav-link">Contact</a>
 </div>
</div>
</nav>
<div className="container-xxl py-5 bg-dark hero-header mb-5">
<div className="container text-center my-5 pt-5 pb-4">
 <h1 className="display-3 text-white mb-3 animated slideInDown">Opening Hours</h1>
 <nav aria-label="breadcrumb">
   <ol className="breadcrumb justify-content-center text-uppercase">
     <li className="breadcrumb-item"><a href="#">Home</a></li>
     {/* <li className="breadcrumb-item"><a href="#">Pages</a></li> */}
     <li className="breadcrumb-item text-white active" aria-current="page">opening hours</li>
   </ol>
 </nav>
</div>
</div>
</div>
{/* Navbar & Hero End */}

{/* About Start */}
<div className="container-xxl py-5">
<div className="container">
<div className="row g-5 align-items-center">
 <div className="col-lg-6">
   <div className="row g-3">
     <div className="col-6 text-start">
       <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.1s" src="img/about-1.jpg" />
     </div>
     <div className="col-6 text-start">
       <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.3s" src="img/about-2.jpg" style={{marginTop: '25%'}} />
     </div>
     <div className="col-6 text-end">
       <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.5s" src="img/about-3.jpg" />
     </div>
     <div className="col-6 text-end">
       <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.7s" src="img/about-4.jpg" />
     </div>
   </div>
 </div>
 <div className="col-lg-6">
   <h5 className="section-title ff-secondary text-start text-primary fw-normal">Hours</h5>
   <h1 className="mb-4">Opening Hours of <i className="fa fa-utensils text-primary me-2" />Harrods Grill</h1>
   <p >At Harrods Grill, we pride ourselves on being there for you whenever hunger strikes. That's why our doors
    swing open 
    </p>
    <h6 className="text-uppercase mb-0">seven days a week</h6>
   <h6 className="text-uppercase mb-0">from 1:00 PM to 11:00 PM,</h6>

   <p >ensuring that you can indulge in our mouthwatering offerings whenever 
    your cravings hit. Whether it's a midday meal or a late-night snack, 
   our team is here to serve you with warmth and hospitality, ensuring that every visit is a satisfying experience.</p>



   <div className="row g-4 mb-4">
     <div className="col-sm-6">
       <div className="d-flex align-items-center border-start border-5 border-primary px-3">
         <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">7</h1>
         <div className="ps-4">
           <p className="mb-0">days</p>
           <h6 className="text-uppercase mb-0">a week</h6>
         </div>
       </div>
     </div>
     <div className="col-sm-6">
       <div className="d-flex align-items-center border-start border-5 border-primary px-3">
         <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">10</h1>
         <div className="ps-4">
           <p className="mb-0">Hours</p>
           <h6 className="text-uppercase mb-0">In a day</h6>
         </div>
       </div>
     </div>
   </div>
   {/* <a className="btn btn-primary py-3 px-5 mt-2" href>Read More</a> */}
 </div>
</div>
</div>
</div>
{/* About End */}



</div>
  )
}
