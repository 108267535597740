import React from 'react'

import Service from '../service/Service'
import About from './Habout'
import Menu from './Hmenu'
import Testimonial from '../testimonial/Testimonial'

export default function Home() {
  return (
<div className="container-xxl bg-white p-0">

          {/* Navbar & Hero Start */}
          <div class="container-xxl position-relative p-0">
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark px-4 px-lg-5 py-3 py-lg-0">
    <a href className="navbar-brand p-0">
      <h1 className="text-primary m-0"><i className="fa fa-utensils me-3" />Harrods  Grill</h1>
      {/* <img src="img/logo.png" alt="Logo"> */}
    </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
      <span className="fa fa-bars" />
    </button>
    <div className="collapse navbar-collapse" id="navbarCollapse">
      <div className="navbar-nav ms-auto py-0 pe-4">
        <a href="/" className="nav-item nav-link active">Home</a>
        <a href="about" className="nav-item nav-link">About</a>
        <a href="menu" className="nav-item nav-link">Menu</a>
        <a href="hours" className="nav-item nav-link">Opening Hours</a>

        {/* <div className="nav-item dropdown">
          <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
          <div className="dropdown-menu m-0">
            <a href="booking.html" className="dropdown-item">Booking</a>
            <a href="team.html" className="dropdown-item">Our Team</a>
            <a href="testimonial.html" className="dropdown-item">Testimonial</a>
          </div>
        </div> */}
        <a href="contact" className="nav-item nav-link">Contact</a>
      </div>
    </div>
  </nav>
  <div className="container-xxl py-5 bg-dark hero-header mb-5">
    <div className="container my-5 py-5">
      <div className="row align-items-center g-5">
        <div className="col-lg-6 text-center text-lg-start">
          <h1 className="display-3 text-white animated slideInLeft">The Tasty House<br /></h1>
          <p className="text-white animated slideInLeft mb-4 pb-2">Where fast food meets exceptional taste in Stanford Le Hope since 2017. 
          Indulge in our mouthwatering burgers, fries, wraps, and salads. Experience
           "The Tasty House" and satisfy your cravings with every bite. Your go-to destination for flavor-packed meals and friendly service seven days a week.</p>
          {/* <a href className="btn btn-primary py-sm-3 px-sm-5 me-3 animated slideInLeft">Book A Table</a> */}
        </div>
        <div className="col-lg-6 text-center text-lg-end overflow-hidden">
          <img className="img-fluid" src="img/hero.png" alt />
        </div>
      </div>
    </div>
  </div>
</div>
{/* Navbar & Hero End */}

<Service/>
        <About />
        <Menu />
        <Testimonial/>

{/* Back to Top */}
<a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up" /></a>

</div>

  )
}
